<template>
  <div
    class="co-flex-col pa-4"
  >
    <v-row :dense="dense">
      <template
        v-if="items.length"
      >
        <v-col
          v-for="(item, i) in items"
          :key="`wk_${i}`"
          cols="12"
          lg="3"
          md="4"
          sm="6"
        >
          <v-hover
            v-slot="{ hover }"
            :disabled="!hovered"
            close-delay="120"
          >
            <v-card
              :elevation="hover ? hoverElevation : normalElevation"
              :class="[round, 'transition-swing']"
              :color="backColor"
              flat
              tile
            >
              <v-img
                :aspect-ratio="ratio"
                :src="item.cover"
                height="inherit"
              >
                <div class="co-flex-col co-justify-between co-h-full">
                  <div
                    v-if="hover"
                    class="co-flex-row co-justify-between co-items-end co-w-full pa-2"
                  >
                    <span
                      v-if="item.thumbs && item.thumbs.length > 1"
                      class="primary co-text-white rounded co-text-2xs px-2 py-1"
                    >组图</span>
                    <span class="co-w20 co-h20" />
                    <v-btn
                      icon
                      dark
                      @click="onDelete(i, item)"
                    >
                      <v-icon>
                        {{ btnDel.icon }}
                      </v-icon>
                    </v-btn>
                  </div>
                  <div
                    class="co-flex-col co-w-full co-flex-grow "
                    @click="toContent(i, item)"
                  />
                  <div
                    v-if="hover"
                    class="co-flex-row co-justify-between co-items-center grey darken-4 co-h40 px-4"
                    style="opacity: 0.70;"
                  >
                    <span
                      class="text-body-1 white--text"
                      @click="toContent(i, item)"
                    >
                      {{ item.title }}
                    </span>
                    <div class="co-flex-row co-items-center">
                      <v-icon
                        size="20"
                        color="white"
                      >
                        {{ icos.like }}
                      </v-icon>
                      <span class="text-body-1 white--text ml-1">
                        {{ item.likesNum }}
                      </span>

                      <v-icon
                        size="20"
                        color="white"
                        class="ml-3"
                        @click="toAlbum(i, item)"
                      >
                        {{ icos.album }}
                      </v-icon>
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="4"
        >
          <div
            class="co-flex-col co-justify-center co-items-center co-border co-border-dash co-border-gray-500 co-w-full co-h-full rounded py-8"
            @click="onUpload"
          >
            <v-icon
              size="64"
              color="blue-grey lighten-3"
            >
              mdi-plus
            </v-icon>
            <span class="co-text-md co-text-gray-500">上传作品</span>
          </div>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
        >
          <div class="co-flex-col co-justify-center co-items-center pa-6">
            <span
              class="co-text-md co-text-gray-500"
            >
              你还没上传作品
              <span
                class="co-text-md primary--text ml-3"
                style="cursor: pointer;"
                @click="toIssue"
              >
                去上传作品
              </span>
            </span>
          </div>
        </v-col>
      </template>
    </v-row>

    <div
      v-if="pagination.length"
      class="d-flex flex-row mt-6 mb-8"
    >
      <v-pagination
        v-model="pagination.current"
        :length="pagination.length"
        :total-visible="pagination.totalVisible"
        @input="changePagination($event)"
      />
    </div>

    <my-works-albums ref="dlgAlbums" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import web from '@/api/web/co.web'

  import mixPreset from '@/pages/mixins/my/mix.my.preset'

  const parts = mixPreset.Parts
  const names = mixPreset.Names
  const part = parts[names.WORKS]

  export default {
    components: {
      MyWorksAlbums: () => import('@/pages/sections/my/MyWorksAlbums.vue')
    },
    props: {
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      dense: {
        type: Boolean,
        default: true
      },
      perColumns: {
        type: [Number, String],
        default: 3
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    data () {
      return {
        name: part.name,
        label: part.label,
        title: part.title,
        total: 0,
        type: part.type,
        ratio: part.ratio,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
        btnDel: {
          icon: 'mdi-close'
        },
        icos: {
          like: 'mdi-thumb-up-outline',
          album: 'mdi-folder-plus-outline'
        },
        dlgAlbum: {
          title: '添加到影集',
          visible: false,
          width: '450px'
        },
        perCols: {
          c1: {
            sm: 12,
            md: 12
          },
          c2: {
            sm: 12,
            md: 6
          },
          c3: {
            sm: 6,
            md: 4
          },
          c4: {
            sm: 6,
            md: 3
          },
        },
      }
    },
    computed: {
      smCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.sm
      },
      mdCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.md
      },
    },
    created () {
      this.configParams()
      this.loadContents()
    },
    methods: {
      setScope (value) {
        const data = this.params.data
        data.accessScope = value
        this.params.reload = true
        api.httpx.getItems(this.params)
      },
      onUpload (ev) {
        api.page.navigate(this, 'upload')
      },
      toIssue () {
        api.page.navigate(this, 'upload')
      },
      toDeleteContent ({
        workIndex,
        itemIndex,
        contentId
      }) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.params.items.splice(workIndex, 1)
            me.items.splice(itemIndex, 1)
            me.$notify({
              title: '成功提示',
              message: '作品删除成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '作品删除失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        web.content.delete({
          id: contentId,
          executed
        })
      },
      toUpdateThumbs ({
        workIndex,
        itemIndex,
        thumbs,
        contentId
      }) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.params.items[workIndex].thumbs = thumbs
            me.items.splice(itemIndex, 1)
            me.$notify({
              title: '成功提示',
              message: '作品删除成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '作品删除失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        web.content.update({
          id: contentId,
          data: {
            thumbs: JSON.stringify(thumbs)
          },
          executed
        })
      },
      toDelete_old (index, item) {
        // console.log({ index, item })
        const arr = item.key.split('-')
        const wkInd = parseInt(arr[1], 10)
        const thInd = parseInt(arr[2], 10)
        if (wkInd < 0 || thInd < 0) {
          this.$notify({
            title: '错误提示',
            message: `无法删除，key = ${item.key}`,
            type: 'error'
          })
          return
        }

        const work = this.params.items[wkInd]
        const thumbs = [
          ...work.thumbs
        ]
        thumbs.splice(thInd, 1)

        if (thumbs.length) {
          this.toUpdateThumbs({
            workIndex: wkInd,
            itemIndex: index,
            thumbs,
            contentId: work.contentId
          })
        } else {
          this.toDeleteContent({
            workIndex: wkInd,
            itemIndex: index,
            contentId: work.contentId
          })
        }
      },
      toDelete (index, item) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.items.splice(index, 1)
            me.$notify({
              title: '成功提示',
              message: '作品删除成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '作品删除失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        web.content.delete({
          id: item.contentId,
          executed
        })
      },
      onDelete (index, item) {
        const me = this
        this.$confirm(
          '确定需要删除此作品吗？',
          '删除提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }
        )
          .then(() => {
            me.toDelete(index, item)
          })
          .catch(action => {})
      },
      toAlbum (inde, item) {
        const dlg = this.$refs['dlgAlbums']
        if (dlg) {
          dlg.show(item)
        }
      },
      toContent (index, item) {
        const params = {
          contentId: item.contentId,
          type: item.type,
          title: item.title
        }

        api.page.navigate(this, 'hotDetail', params)
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configItems_old () {
        this.items = []
        for (const i in this.params.items) {
          const src = this.params.items[i] || {}
          const thumbs = src.thumbs || []
          for (const j in thumbs) {
            const cover = thumbs[j]
            const dst = {
              key: `img-${i}-${j}`,
              contentId: src.contentId,
              categoryId: src.categoryId,
              circleId: src.circleId,
              title: src.title,
              cover,
              type: src.type,
              likesNum: src.likesNum,
              collectNum: src.collectNum,
              hitNum: src.hitNum,
              forwardedNum: src.forwardedNum,
              commentNum: src.commentNum
            }

            this.items.push(dst)
          }
        }
      },
      configItems () {
        for (const i in this.params.items) {
          const src = this.params.items[i] || {}
          const thumbs = src.thumbs || []
          if (!src.cover) {
            src.cover = thumbs[0] || ''
          }
        }
      },
      configParams () {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.total = res.total
            me.title = `${me.label} ${res.total}`
            app.emit(app.event.MY_WORKS_GET, {
              name: me.name,
              title: me.title,
              label: me.label,
              total: res.total
            })
            const data = me.params.data
            const pagination = me.pagination
            const pageSize = parseInt(data.pageSize) || 9
            pagination.length = Math.ceil(res.total / pageSize)
          }
        }

        me.params = web.content.getParamsPersonal({
          type: me.type,
          accessScope: web.comm.Scopes.PUBLIC,
          caches: this.items,
          executed
        })
      },
      loadContents (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
    }
  }
</script>
